export enum EnvironmentVariable {
  AZURE_APP_ID = 'PUBLIC_AZURE_APP_ID',
  BCC_EMAIL_HOSTNAME = 'PUBLIC_BCC_EMAIL_HOSTNAME',
  CUBE_ORIGIN = 'PUBLIC_CUBE_ORIGIN',
  ENVIRONMENT_TTL = 'PUBLIC_ENVIRONMENT_TTL',
  FLATFILE_PUBLISHABLE_KEY = 'PUBLIC_FLATFILE_PUBLISHABLE_KEY',
  GRAPHQL_ORIGIN = 'PUBLIC_GRAPHQL_ORIGIN',
  HOCUSPOCUS_ORIGIN = 'PUBLIC_HOCUSPOCUS_ORIGIN',
  HOCUSPOCUS_UPDATES_V2_ORIGIN = 'PUBLIC_HOCUSPOCUS_UPDATES_V2_ORIGIN',
  HOST = 'PUBLIC_HOST',
  KANBAN_PARTITION_SIZE_DEFAULT = 'PUBLIC_KANBAN_PARTITION_SIZE_DEFAULT',
  RECAPTCHA_KEY = 'PUBLIC_RECAPTCHA_KEY',
  WS_ORIGIN = 'PUBLIC_WS_ORIGIN',
  ENABLE_PIPELINE_STAGE_TRANSITION_APPROVALS = 'PUBLIC_ENABLE_PIPELINE_STAGE_TRANSITION_APPROVALS',
  ENABLE_SELECTABLE_SHAREPOINT_SCOPES = 'PUBLIC_ENABLE_SELECTABLE_SHAREPOINT_SCOPES',
}

export type EnvironmentRecord = Partial<Record<EnvironmentVariable, string | undefined>>

export interface Environment {
  /**
   * Gets the environment variable as a boolean value.
   *
   * @param key
   * @param options
   * @param options.defaultValue Defaults to `false`
   * @returns
   */
  getBoolean(key: EnvironmentVariable, options?: { defaultValue?: boolean }): boolean

  /**
   * Gets the environment variable as an integer value.
   *
   * @param key
   * @param options
   * @param options.defaultValue Defaults to `0`
   * @returns
   */
  getInteger(key: EnvironmentVariable, options: { defaultValue: number }): number

  /**
   * Gets the environment variable as a string value.
   *
   * @param key
   * @param options
   * @param options.defaultValue Defaults to `''`
   * @returns
   */
  getString(key: EnvironmentVariable, options?: { defaultValue?: string }): string

  /**
   * Allows you to reinitialize the environment. This is mostly used for testing at the moment.
   */
  reload(): Environment
}
