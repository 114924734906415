import { forwardRef, HTMLAttributes } from 'react'
import { classNames } from '@helpers/scls'
import styles from './Flex.module.scss'
import { Flex as MantineFlex, FlexProps as MantineFlexProps } from '@mantine/core'

type FlexSpacing = 'none' | 'sm' | 'md' | 'lg'
type FlexGrow = 'none' | 'sm' | 'md' | 'lg'

export interface FlexProps extends HTMLAttributes<HTMLElement> {
  align?: MantineFlexProps['align']
  direction?: MantineFlexProps['direction']
  gap?: MantineFlexProps['gap']
  justify?: MantineFlexProps['justify']
  wrap?: MantineFlexProps['wrap']
  grow?: FlexGrow

  scrollX?: boolean
  scrollY?: boolean

  sx?: MantineFlexProps['sx']

  p?: FlexSpacing
  px?: FlexSpacing
  pl?: FlexSpacing
  pr?: FlexSpacing
  py?: FlexSpacing
  pb?: FlexSpacing
  pt?: FlexSpacing

  m?: FlexSpacing
  mx?: FlexSpacing
  ml?: FlexSpacing
  mr?: FlexSpacing
  my?: FlexSpacing
  mb?: FlexSpacing
  mt?: FlexSpacing

  fullHeight?: boolean
  fullWidth?: boolean
}

const Flex = forwardRef((props: FlexProps, ref: any) => {
  const {
    className: propsClassName,
    scrollX,
    scrollY,
    grow = 'none',
    fullHeight,
    fullWidth,
    p = 'none',
    px = p,
    pl = px,
    pr = px,
    py = p,
    pt = py,
    pb = py,
    m = 'none',
    mx = m,
    ml = mx,
    mr = mx,
    my = m,
    mb = my,
    mt = my,
    ...forwarded
  } = props

  const className = classNames(
    styles.root,
    {
      [styles.scrollX]: scrollX,
      [styles.scrollY]: scrollY,
      [styles.scrollNone]: !scrollX && !scrollY,

      [styles.plNone]: pl === 'none',
      [styles.plSm]: pl === 'sm',
      [styles.plMd]: pl === 'md',
      [styles.plLg]: pl === 'lg',
      [styles.prNone]: pr === 'none',
      [styles.prSm]: pr === 'sm',
      [styles.prMd]: pr === 'md',
      [styles.prLg]: pr === 'lg',
      [styles.ptNone]: pt === 'none',
      [styles.ptSm]: pt === 'sm',
      [styles.ptMd]: pt === 'md',
      [styles.ptLg]: pt === 'lg',
      [styles.pbNone]: pb === 'none',
      [styles.pbSm]: pb === 'sm',
      [styles.pbMd]: pb === 'md',
      [styles.pbLg]: pb === 'lg',

      [styles.mlNone]: ml === 'none',
      [styles.mlSm]: ml === 'sm',
      [styles.mlMd]: ml === 'md',
      [styles.mlLg]: ml === 'lg',
      [styles.mrNone]: mr === 'none',
      [styles.mrSm]: mr === 'sm',
      [styles.mrMd]: mr === 'md',
      [styles.mrLg]: mr === 'lg',
      [styles.mtNone]: mt === 'none',
      [styles.mtSm]: mt === 'sm',
      [styles.mtMd]: mt === 'md',
      [styles.mtLg]: mt === 'lg',
      [styles.mbNone]: mb === 'none',
      [styles.mbSm]: mb === 'sm',
      [styles.mbMd]: mb === 'md',
      [styles.mbLg]: mb === 'lg',

      [styles.growSm]: grow === 'sm',
      [styles.growMd]: grow === 'md',
      [styles.growLg]: grow === 'lg',

      [styles.fullHeight]: fullHeight,
      [styles.fullWidth]: fullWidth,
    },
    propsClassName
  )

  return <MantineFlex className={className} {...forwarded} ref={ref} />
})

export default Flex
