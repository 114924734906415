import Navigation from '@components/landingpage/Navigation'
import { ModalV2Anchor } from '@components/ModalV2/ModalV2Anchor'
import Page from '@components/page/Page'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import useIsMobile from '@helpers/hooks/IsMobileHook'
import { createTheme, ThemeProvider } from '@material-ui/core'
import { isEmbeddedUrl } from '@src/apollo/mfa-redirect'
import { NextStageDb } from '@src/indexeddb/NextStageDb'
import { useGetMe } from '@src/partials/app/hooks/useGetMe'
import { useGetWorkspace } from '@src/partials/app/hooks/useGetWorkspace'
import { PUBLIC_ROUTES } from '@src/partials/app/public-routes'
import { useBuildVersionInterval } from '@src/partials/app/useBuildVersionInterval'
import { useGovWinToast } from '@src/partials/app/useGovWinToast'
import { useSnackbarEvents } from '@src/partials/app/useSnackbarEvents'
import { WorkspaceChangeModal } from '@src/partials/app/WorkspaceChangeModal'
import { createNextStageStore } from '@src/redux/create-store'
import Cohere from 'cohere-js'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Link from 'next/link'
import Router, { useRouter } from 'next/router'
import { useMemo } from 'react'
import 'react-calendar-timeline/lib/Timeline.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-grid-layout/css/styles.css'
import { Provider } from 'react-redux'
import 'react-resizable/css/styles.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { withApollo } from '../apollo/apollo'
import '../styles/global.scss'

import styles from './_app.module.scss'
import { MantineProvider } from '@mantine/core'

// DO NOT REMOVE!! This is NOT a mistake!
const SharePointIntegrationsRootImports = dynamic(
  () => import('@components/Integrations/SharePoint/CommonSharePointMGTToolkit'),
  {
    ssr: false,
  }
)

const Modal = dynamic(() => import('@components/modal/Modal'))
const Drawer = dynamic(() => import('@components/drawer/Drawer'))
const SideMenu = dynamic(() => import('@components/sidemenu/SideMenu'), {
  loading: () => <div className={styles.sidemenuPlaceholder} />,
})
const Verify = dynamic(() => import('@components/verify/Verify'))
const Upgrade = dynamic(() => import('@components/upgrade/Upgrade'))

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
  try {
    window.analytics.page(url)
  } catch {}
})

Cohere.init(process.env.NEXT_PUBLIC_COHERE_API_KEY || '', {
  disableRecording: process.env.NODE_ENV !== 'production' || process.env.NEXT_PUBLIC_DISABLE_COHERE === 'true',
})

config.autoAddCss = false

declare global {
  interface Window {
    analytics: any
  }
}

const App = ({ Component, pageProps }) => {
  const router = useRouter()

  useBuildVersionInterval()
  useGovWinToast()
  useSnackbarEvents()

  const isMobile = useIsMobile()

  const refetches = () => {
    refetch()
    wsRefetch()
  }

  const { error, data, loading, refetch } = useGetMe()

  const { data: wsData, loading: wsLoading, error: wsError, refetch: wsRefetch } = useGetWorkspace()

  const showEmailVerification = data?.getMe && !data?.getMe?.isVerifiedEmail
  const showDisabledMessage = data && !data?.getMe?.isActive
  const showPayWall = data?.getMe && !data?.getMe?.freeTrial && !data?.getMe?.isPayingCustomer

  const route = router.pathname.split('/')[1]
  const isPublicRoute = PUBLIC_ROUTES.includes(route)

  const workspace = wsData?.getWorkspace

  if (isPublicRoute) {
    return (
      <Component
        {...pageProps}
        refetch={refetches}
        isEmbedded={isEmbeddedUrl(router.pathname, router.query as Record<string, string | string[]>)}
      />
    )
  }

  if (!data || (!wsData && !error && !wsError)) {
    console.error('Loader no data!', error, loading, data, wsData, wsError)
    return (
      <Page>
        <Page.Loader />
      </Page>
    )
  }

  // Authenticated routes

  if (!data?.getMe) {
    return (
      <>
        <Navigation notFixed login={false} />
        <div className="container--vcentered margin-top-1" style={{ padding: '1rem 2rem' }}>
          <span className="smallheader">Please sign in to NextStage.</span>
          <br />
          <div>
            <Link href="/login">Click here</Link> if you are not immediately redirected.
          </div>
        </div>
      </>
    )
  }

  if (showDisabledMessage) {
    return (
      <>
        <Navigation notFixed login={false} />
        <div className="margin-top-1 textLarge" style={{ padding: '1rem 2rem' }}>
          Your account has been disabled.
          <br />
          Please contact your workspace's admin to restore access to NextStage.
        </div>
      </>
    )
  }

  if (error && error.toString().includes('Network error: Failed to fetch')) {
    // console.log('Error message: ', error)
    return (
      <Page>
        <Page.Error>Server failed to respond, please try refreshing the page</Page.Error>
      </Page>
    )
  }

  // If "embedded" is true, the we don't show navigation side menu.
  console.log("Checking if we're embedded!", router.pathname, router.query.embedded)

  if (isEmbeddedUrl(router.pathname, router.query as Record<string, string | string[]>)) {
    console.log('We are embedded!')

    return (
      <div>
        <WorkspaceChangeModal />
        <Component {...pageProps} refetch={refetches} isEmbedded={true} />
        <Modal />
        <Drawer />
      </div>
    )
  }

  if (['verify', 'settings', 'admin'].includes(route)) {
    return (
      <div className={styles.appWrapper}>
        <SideMenu />
        <Component {...pageProps} refetch={refetches} />
        <Modal />
      </div>
    )
  }

  if (showPayWall) {
    return (
      <div className={styles.appWrapper}>
        <SideMenu />
        <Upgrade />
      </div>
    )
  }

  if (showEmailVerification) {
    return (
      <div className={styles.appWrapper}>
        <SideMenu />
        <Verify
          user={data?.getMe}
          // refetch={refetches}
        />
      </div>
    )
  }

  return (
    <div className={styles.appWrapper}>
      {isMobile && (
        <Head>
          <meta name="viewport" content="viewport-fit=cover initial-scale=1" />
          <meta name="mobile-web-app-capable" content="yes"></meta>
        </Head>
      )}
      <WorkspaceChangeModal />
      <SideMenu />
      <Component {...pageProps} refetch={refetches} />
      <Modal />
      <Drawer />
    </div>
  )
}

export const customTheme = createTheme({
  overrides: {
    MuiToolbar: {
      regular: {
        height: 48,
        minHeight: 48,
      },
    },
  },
})

const MyApp = ({ Component, pageProps }) => {
  const store = useMemo(() => {
    // if (pageProps.store) console.log('Hydrate Redux', JSON.parse(pageProps.store))
    return createNextStageStore(pageProps.store ? JSON.parse(pageProps.store) : undefined)
  }, [])

  return (
    <ThemeProvider theme={customTheme}>
      <MantineProvider
        theme={{
          colors: {
            'blue-primary': [
              '#daf8ff',
              '#aee5ff',
              '#7ed1ff',
              '#4dbfff',
              '#23adfe',
              '#0f93e5',
              '#0072b3',
              '#005281',
              '#003150',
              '#001120',
            ],
            yellow: [
              '#fef8e6',
              '#fbe9ba',
              '#fadb8b',
              '#f9cd5c',
              '#f8bf33',
              '#dfa623',
              '#ad811a',
              '#7c5b12',
              '#4a3709',
              '#191201',
            ],
            red: [
              '#ffe5e3',
              '#fbbcb8',
              '#f3958c',
              '#ec705f',
              '#e63c32',
              '#cd191c',
              '#a0131f',
              '#730c1c',
              '#470514',
              '#1e000b',
            ],
            green: [
              '#e1fced',
              '#bfeed5',
              '#9be1bf',
              '#76d4a9',
              '#51c895',
              '#37ae80',
              '#28875c',
              '#1a613d',
              '#083b20',
              '#001605',
            ],
          },
          primaryColor: 'blue-primary',
          primaryShade: 6,
        }}
      >
        <NextStageDb>
          <Provider store={store}>
            <ModalV2Anchor>
              <SharePointIntegrationsRootImports>
                <App Component={Component} pageProps={pageProps} key={pageProps.key ?? undefined} />
                <ToastContainer toastClassName="toast" bodyClassName="toastBody" />
              </SharePointIntegrationsRootImports>
            </ModalV2Anchor>
          </Provider>
        </NextStageDb>
      </MantineProvider>
    </ThemeProvider>
  )
}

export default withApollo({ ssr: false })(MyApp)
