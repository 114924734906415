import Link from 'next/link'
import styles from './Logo.module.scss'
import Image from '@components/image/Image'
import { getHostOrigin } from '@helpers/origin'

interface LogoProps {
  customStyles?: any
  noLink?: boolean
  className?: string
}

const Logo = ({ customStyles, noLink, className = '' }: LogoProps) => {
  if (noLink) {
    return (
      <a className={styles.logo + ` ${className}`} style={customStyles}>
        NextStage
      </a>
    )
  }
  return (
    <Link href="/">
      <img
        className={className}
        alt="NextStage Logo"
        src={'/images/logo/wordmark_color_sm.webp'}
        style={{ cursor: 'pointer' }}
      />
    </Link>
  )
}

export default Logo

interface NLogoProps {
  src?: string
  width?: number
  height?: number
}

export const NLogo = ({ src, width = 20, height = 25 }: NLogoProps) => {
  return <Image alt="NextStage Logo" src={src || '/images/logo/emblem_color_sm.webp'} width={width} height={height} />
}

export const NextStageWordLogo = ({ src, width = 20, height = 25 }: NLogoProps) => {
  // If we're on a different environment subdomain, we need to redirect to the correct origin
  const origin = getHostOrigin() ?? 'https://nextstage.ai'
  return (
    <a href={origin}>
      <Image
        alt="NextStage Logo"
        src={src || '/images/logo/wordmark_color_sm.webp'}
        width={width}
        height={height}
        customStyles={{ cursor: 'pointer' }}
      />
    </a>
  )
}
