import { NextStageWordLogo } from '@components/logo/Logo'
import useIsMobile from '@helpers/hooks/IsMobileHook'
import { classNames } from '@helpers/scls'
import { useSelector } from '@src/redux/use-selector'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'

import styles from './Navigation.module.scss'

interface NavigationProps {
  notFixed?: boolean
  head?: React.ReactNode
  login?: boolean
  withShadow?: boolean
}

const Navigation = (props: NavigationProps) => {
  const { notFixed = false, head = null, login = true, withShadow = false } = props
  const authed = useSelector((state) => state.user.id)
  const router = useRouter()
  const isMobile = useIsMobile()

  const canonicalUrl = (`https://nextstage.ai` + (router.asPath === '/' ? '' : router.asPath)).split('?')[0]

  const demoLink = process.env.NEXT_PUBLIC_DEMO_URL ?? 'https://get.nextstage.ai/request-demo-ws'

  return (
    <div className={classNames(styles.container, { [styles.withShadow]: withShadow })}>
      <Head>
        <title>{head || 'NextStage Government Contracting CRM'}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Head>

      <div className={notFixed ? styles.topMenuNotFixed : styles.topMenu}>
        <div className={styles.logoNavGroup}>
          {isMobile ? <NextStageWordLogo height={34} width={160} /> : <NextStageWordLogo height={43} width={200} />}
        </div>
        <div>
          <a href={`/pricing`}>
            <a className={styles.login} style={{ marginRight: '1.5em' }}>
              Pricing
            </a>
          </a>
          {authed ? (
            <Link href="/home">
              <a className={styles.login}>Login</a>
            </Link>
          ) : login ? (
            <>
              {!isMobile && (
                <a href={demoLink} rel="nofollow" className={styles.demo}>
                  Schedule Demo
                </a>
              )}
              <Link href="/login">
                <a className={styles.login}>Log In</a>
              </Link>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Navigation
