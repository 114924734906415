export function isEmbeddedUrl(pathname: string, query?: Record<string, string | string[]>) {
  // Includes leading /
  //   /office/word
  if (pathname.startsWith('/office') || pathname.includes('embedded')) {
    return true
  } else if (query?.embedded === 'true') {
    return true
  }

  return false
}
