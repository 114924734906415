export function mapToValidHref(href: string) {
  if (!href) return undefined
  if (!href.includes('.') || href.match(/^\w+:\/\//)) return href
  return `http://${href}`
}

export function mapToWebsocketUrl(url: string) {
  return url.replace(/^https/, 'wss').replace(/^http/, 'ws')
}

/**
 * Creates the path portion of a URL from the given parts.
 *
 * @param parts
 * @returns
 */
export function createPath(...parts: string[]) {
  return ('/' + parts.join('/')).replace(/\/+/g, '/')
}
